import './App.css';
import { MathFactsGenerator } from './MathFactsGenerator/MathFactsGenerator.tsx';
import React from 'react';
import { SideNav } from './SideNav/SideNav.tsx';
import { Charts } from './Charts/Charts.tsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ProcessUrl } from './MathFactsGenerator/UrlProcessor/ProcessUrl.tsx';
import { DefaultSettings } from './MathFactsGenerator/Models.ts';

function App() {  

  const urlQuery = window.location.search;
  let initialSettings = DefaultSettings;
    if (urlQuery) {
        initialSettings = ProcessUrl(urlQuery);
    }

  return (    
    <div className="App">      
      <div>
      <Router>
        <SideNav/>
          <Routes>
            <Route exact path="" element={                
                <MathFactsGenerator initialSettings={initialSettings}/>
              }
            />
            <Route path="/charts" element={
                <Charts />
              }
            />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
