import React, { useRef } from 'react'
import './SideNav.css'
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { Toast } from 'primereact/toast';
import { MenuPages } from './MenuConstants.tsx';

export function SideNav() {
  const menuLeft = useRef(null);
  const toast = useRef(null);
  const items = [
    {
        label: 'Home',
        icon: 'pi pi-home',
        command:()=>{ window.location.pathname =""; }
    },
    {
        label: 'Addition',
        icon: 'pi pi-plus',
        command:()=>{ window.location.pathname ="/addition"; }
    },
    {
        label: 'Subtraction',
        icon: 'fa-solid fa-minus',
        command:()=>{ window.location.pathname ="/subtraction"; }
    },
    {
        label: 'Multiplication',
        icon: 'pi pi-times',
        command:()=>{ window.location.pathname ="/multiplication"; },
    },
    {
        label: 'Division',
        icon: 'fa-solid fa-divide',
        command:()=>{ window.location.pathname ="/division"; }
    },
    {
        label: MenuPages.Charts,
        icon: 'fa-solid fa-calendar',
        items: [
            {
                label: 'Hundreds Chart',
                command:()=>{ window.location.pathname ="/charts"; }
            }            
        ]
    },
    // {
    //     label: 'Blog', // Add submenu to this one
    //     icon: 'fa-solid fa-blog',
    //     command:()=>{ window.location.pathname ="/blog"; }
    // },
    // {
    //     label: MenuPages.GradeLevel, // Add submenu to this one
    //     icon: 'fa-solid fa-layer-group',
    //     command:()=>{ window.location.pathname ="/GradeLevel"; }
    // }
          
  ];
  return (
    <div className='menuContainer'>
      <Toast ref={toast}></Toast>
      <TieredMenu model={items} popup ref={menuLeft} id="popup_menu_left" />
      <Button label="Menu" icon="pi pi-align-left" className="mr-2" onClick={(event) => menuLeft.current.toggle(event)} aria-controls="popup_menu_left" aria-haspopup />
    </div>
  )
}