import { getRandomInt } from "../Shared/Helpers.tsx";
import { Operations, ProblemData, RegroupingOptions } from "./Constants.ts";
import { NumberRange, SettingsProps } from "./Models.tsx";


export function GenerateData(settings: SettingsProps) {
    let problems: ProblemData[] = [];
    let pages: ProblemData[][] = [];
    for(let i = 0; i < settings.NumberOfPages; i++) {
      for(let j = 0; j < settings.NumberOfProblemsPerPage; j++) {
        let numbers: number[] = [];
        
        for(let k = 0; k < settings.NumberRanges.length; k++) {
          numbers.push(generateDigits(settings.NumberRanges[k]));
        }
        const operator = settings?.Operators[Math.floor(Math.random() * settings?.Operators.length)];
        if (operator?.value !== Operations.LongDivision && settings.Regrouping === RegroupingOptions.Both) {
          shuffleArray(numbers);  
        }            
        
        if (operator?.value === Operations.Subtraction) {
          if (numbers[0] < numbers[1]) {
            const temp = numbers[0];
            numbers[0] = numbers[1];
            numbers[1] = temp;
            numbers.push(numbers[0] - numbers[1]);
          }
          else {
            numbers.push(numbers[0] - numbers[1])
          }
        }
        
        if (operator?.value === Operations.Division) {
          const temp = numbers[0];
          numbers.push(numbers[1]);
          numbers[0] = numbers[0] * numbers[1];
          numbers[1] = temp;  
          if (numbers[0] === 0 && numbers[1] === 0) {
            numbers[2] = 0;
          }      
        }

        if (operator?.value === Operations.LongDivision) {
          const divisor = numbers[0];
          numbers.push(numbers[1]);
          numbers[0] = numbers[0] * numbers[1]; // dividend
          numbers[1] = divisor;
          if (settings.Remainders) { 
            const remainder = Math.floor(Math.random() * (divisor - 1));
            numbers[0] = numbers[0] + remainder;
            numbers[3] = remainder;
          }

          if (numbers[0] === 0 && numbers[1] === 0) {
            numbers[2] = 0;
          }      
        }
  
        if (operator?.value === Operations.Multiplication) {
          numbers.push(numbers[0] * numbers[1]);
        }
  
        if (operator?.value === Operations.Addition) {          
          if (settings.Regrouping === RegroupingOptions.NoRegrouping) {
            var newSecondNumber = '';
            var smallerNumberOfDigits = 
              settings.NumberRanges[0].NumberOfDigits > settings.NumberRanges[1].NumberOfDigits ? 
              settings.NumberRanges[1].NumberOfDigits : 
              settings.NumberRanges[0].NumberOfDigits;

            var numberUpdated = false;
            for (var l = 0; l < smallerNumberOfDigits; l++) {
              var divisor = l === 0 ? 1 : Math.pow(10, l);
              var firstDigit = Math.floor((numbers[0] / divisor) % 10);
              var secondDigit = Math.floor((numbers[1] / divisor) % 10);
              var greaterThan10 = (firstDigit + secondDigit) >= 10;
              if (greaterThan10) {
                var maxNumber = 9 - firstDigit;
                secondDigit = getRandomInt(0, maxNumber); 
                numberUpdated = true;             
              }
              newSecondNumber = secondDigit.toString() + newSecondNumber;
            }
            if (numberUpdated) {
              var newNumber2 = parseInt(newSecondNumber);
              numbers[1] = newNumber2;
            }            
          }
          if (settings.Regrouping === RegroupingOptions.RegroupingOnly) {
            newSecondNumber = '';
            smallerNumberOfDigits = 
              settings.NumberRanges[0].NumberOfDigits > settings.NumberRanges[1].NumberOfDigits ? 
              settings.NumberRanges[1].NumberOfDigits : 
              settings.NumberRanges[0].NumberOfDigits;

            numberUpdated = false;
            for (l = 0; l < smallerNumberOfDigits; l++) {
              divisor = l === 0 ? 1 : Math.pow(10, l);
              firstDigit = Math.floor((numbers[0] / divisor) % 10);
              secondDigit = Math.floor((numbers[1] / divisor) % 10);
              var lessThan10 = (firstDigit + secondDigit) < 10;
              if (lessThan10 && !numberUpdated) {
                if (firstDigit === 0) {
                  firstDigit = 1;
                  var multiplier = l === 0 ? 1 : Math.pow(10, l);
                  numbers[0] = numbers[0] + multiplier;
                }
                var minNumber = 10 - firstDigit;
                secondDigit = getRandomInt(minNumber, 9); 
                numberUpdated = true;             
              }
              newSecondNumber = secondDigit.toString() + newSecondNumber;
            }
            if (numberUpdated) {
              newNumber2 = parseInt(newSecondNumber);
              numbers[1] = newNumber2;
            } 
          }
          shuffleArray(numbers);
          numbers.push(numbers[0] + numbers[1]);
        }  
        problems.push({
          key: i + 1,
          numbers: numbers,
          operator: operator?.sign,
        })      
     } 
     pages.push(problems);
      problems = []; 
    }
    return pages;
  }

function generateDigits(range: NumberRange) : number {
    const randomIndex = getRandomInt(0, range.NumberRangeSelection.length - 1);
    let num = range.NumberRangeSelection[randomIndex];
    switch(range.NumberOfDigits) {
      case 2: {
        num = num + (getRandomInt(0, 9));
        break;
      }
      case 3: {
        num = num + (getRandomInt(0, 99));
        break;
      }
    }
    return num;
  }

  function shuffleArray(array) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }