import React, { useContext } from "react";
import { SettingsContext } from "./SettingsContext.tsx";
import { SelectButton } from "primereact/selectbutton";
import { RegroupingOptions } from "../Constants.ts";


export function AdditionSettings() {
    const {settings, setSettings} = useContext(SettingsContext);

    const regroupingOptionsArray = Object.values(RegroupingOptions);
    regroupingOptionsArray.splice(0, (regroupingOptionsArray.length - 1)/2);

    function handleRegroupingSelection(value) {
        let updatedSettings = {...settings}; 
        updatedSettings.Regrouping = value;
        setSettings(updatedSettings);
    }
    return(
        <div className='selectContainer'>
                <label>Regrouping: </label>
                <SelectButton 
                    value={settings.Regrouping} 
                    onChange={(e) => handleRegroupingSelection(e.value)} 
                    options={ Object.values(RegroupingOptions) }
                    className='select'                    
                />
            </div>
    );
}